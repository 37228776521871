.services-box {
    display: flex;
    flex-direction: column;
    gap:3vh;
    padding:1.5vw 0 0 0;
    min-width: 100vw;
    justify-content: center;
    align-items: center;
    background-color: #fafafa;
}

.services-text-box {
    display: flex;
    flex-direction: column;
    gap:2vh;
    justify-content: center;
    align-items: center;
    max-width: 30vw;
}

.services-sub-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    padding: 1.5vw 0 1.5vw 0;
}

@media (max-width:1024px) {
    .services-sub-box {
        flex-direction: column;
    }
    .services-text-box {
        max-width: 70vw;
        gap:.5vh;
    }
    .services-box {
        gap:1vh;
    }
}

.services-title {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 400;
}

.services-text {
    font-size: 1.5rem;
    margin: 0;
    text-align: justify;
}

.services-link {
    font-size: 1.5rem;
    margin: 0;
    color: rgb(207, 109, 4);
    font-weight: 400;
}

.prices-text {
    margin:0;
    font-size: 2rem;
    font-weight: 400;
    color:rgb(207, 109, 4);
}

.prices-specs-text {
    margin:0;
    font-size: 1.5rem;
    font-weight: 400;
    color:rgb(207, 109, 4);
}

.insurance-text {
    margin:0;
    font-size: 1.7rem;
    color:rgb(207, 109, 4);
    font-weight: 400;
    margin-bottom: 2vh;
}

.services-image {
    width: 40vw;
    height: auto;
}

@media (max-width: 1024px) {
    .services-image {
        width: 50vw;
    }
}

.services-procedure-box {
    display: flex;
    flex-direction: column;
    gap:.5vh;
}