html {
  font-size: 12px;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

@media (max-width:600px) {
  html {
    font-size: 8px;
  }
}

body {
  margin:0;
  padding:0;
  box-sizing: border-box;
  width: 100vw;
  height: 100vh;
  font-family: 'Oswald', sans-serif;
  font-weight: 200;
  background-color: #fafaf9;
  color: #171717;
}

#root {
  display:grid;
  grid-template-columns: 100vw;
  grid-template-areas: 
      "topnav"
      "content"
}

