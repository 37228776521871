.top-nav-box {
    display:flex;
    align-items: center;
    grid-area: topnav;
    flex-direction: row;
    justify-content: center;
    border-bottom:1px solid #d4d4d8;
    width: 100%;
    background-color: #f4f4f5;
    padding:8px 16px;
    box-sizing: border-box;
}

.top-nav-links {
    flex:1;
    justify-content: center;
    flex-direction: row;
    display: flex;
    gap:5vw;
    list-style: none;
    font-weight: 400;
    font-size: 1.5rem;
    margin:0;
    
}

.top-nav-socialmedia-box {
    margin:auto;
}

.top-nav-socialmedia-png {
    filter: invert(100%) sepia(7%) saturate(5605%) hue-rotate(180deg) brightness(101%) contrast(70%);
    width: 30px;
    height: auto;
}

.blue-hover:hover {
    filter: invert(28%) sepia(83%) saturate(1370%) hue-rotate(176deg) brightness(89%) contrast(98%);
    cursor: pointer;
}

.pink-hover:hover {
    filter: invert(63%) sepia(21%) saturate(773%) hue-rotate(305deg) brightness(113%) contrast(98%);
    cursor: pointer;
}

.top-nav-button-box {
    margin:auto;
}

.top-nav-button {
    background-color: #d4d4d8;
    border: none;
    outline: none;
    padding:12px;
    font-size: 1.4rem;
    font-family: 'Oswald', sans-serif;
    border-radius: .25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 30px;
}

.top-nav-button:hover {
    cursor: pointer;
    background-color: #fb923c;
}

a {
    text-decoration: none;
    color:#171717;
  }

a:hover {
    color: #0369a1;
}