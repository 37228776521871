.home-box {
    grid-area: content;
    display:flex;
    flex-direction: column;
    min-width: 100vw;
    box-sizing: border-box;
    background-color: #fafafa;
}

.with-padding {
    padding: 1.5vw 0 1.5vw 0;
}

.overview-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    align-items: center;
}

@media (max-width: 1024px) {
    .overview-box {
        flex-direction: column;
        gap:1vh;
    }
}

.text-box, .text-box-skala, .text-box-nedclimb {
    display: flex;
    flex-direction: column;
    gap:1.5vh;
    justify-content: center;
    align-items: center;
    text-align: center;
    max-width: 30vw;
}

.text-box-skala {
    position: absolute;
    left:15%;
    top:30%;
}

.text-box-nedclimb {
    position:absolute;
    left:55%;
    top:30%;
}

@media (max-width:1024px) {
    .text-box-nedclimb {
        top:15%;
        left:58%
    }
    .text-box-skala {
        left: 68%;
        top:5%;
        max-width: 30vw;
    }
    .text-box, .text-box-nedclimb, .text-box-skala {
        gap:.5vh;
        max-width: 40vw;
    }
}

.image-box {
    display: flex;
    justify-content: center;
    align-items: center;
}

.image-box-stretch {
    min-width: 100vw;
    min-height: 50vh;
    overflow: hidden;
    background-size:cover;
    background-repeat: no-repeat;
    background-position: 50%;
    background-clip: border-box;
    background-origin: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
}

.skala {
    background-image: url('../img/skalaontherockthin.jpg');
}

.nedclimb {
    background-image: url('../img/nedclimbs.jpg');
    background-position: left;
}

@media (max-width: 1024px) {
    .nedclimb {
        background-position: 10%;
    }
}

.home-image {
    max-width: 20vw;
    height: auto;
}

@media (max-width:1024px) {
    .home-image {
        max-width: 50vw;
    }
}

.home-image-stretch {
    min-width: 100vw;
}

.home-text {
    font-size: 2.5rem;
    font-weight: 400;
    margin:0;
}

.white-text {
    color:#fafaf9
}

@media (max-width: 1024px) {
    .home-text {
        font-size: 2rem;
    }
}

.home-btn {
    border: 3px solid rgb(219, 113, 0);
    border-radius: 1rem;
    padding: .8vh;
    color: white;
    font-weight: 400;
    font-size: 1.6rem;
    background-color: rgb(219, 113, 0);
    transition: background-color .3s ease-out .05s, color .3s ease-out .05s;
    vertical-align: middle;
}

.home-btn:hover {
    background-color: #fafafa;
    color: rgb(219, 113, 0);
    cursor: pointer;
}

@media (max-width:1024px) {
    .home-btn {
        font-size: 1.3rem;
    }
}