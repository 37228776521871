.shop-text {
    font-size: 2rem;
    text-align: justify;
}

.shop-images {
    width: 30vw;
    height: auto;
}

@media (max-width:1024px) {
    .shop-images {
        width: 50vw;
        height: auto;
    }
}

.shop-title {
    margin: 0;
    font-size: 2.5rem;
    font-weight: 400;
}

.shop-logo {
    width: 10vw;
    height: auto;
}


@media (max-width:1024px) {
    .shop-logo {
        width: 20vw;
        height: auto;
    }
}

.shop-link {
    color:#0369a1;
    font-weight: 400;
}

.shop-link:hover {
    color: rgb(219, 113, 0);
}

.shop-link-alternative {
    color:rgb(219, 113, 0);
    font-weight: 400;
}

.shop-link-alternative:hover {
    color: #0369a1;
}