.about-box {
    grid-area: content;
    display:flex;
    flex-direction: column;
    min-width: 100vw;
    padding: 1.5vw 0 0 0;
    background-color: #fafafa;
}

.about-subbox {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5vw;
    align-items: center;
    margin-bottom: 1.5vw;
}

@media (max-width:1024px) {
    .about-subbox {
        flex-direction: column;
    }
}

.about-title-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-image {
    max-width: 25vw;
    height: auto;
}

@media (max-width:1024px) {
    .about-image {
        max-width: 45vw;
    }
}

.reseau-image {
    max-width: 20vw;
    height: auto;
    border: .5px solid rgb(207, 109, 4);
}

.about-name, .about-title {
    margin:0;
}

.about-name {
    font-size: 2.5rem;
    color:rgb(219, 113, 0);
}

.about-title {
    font-size: 2rem;
    font-weight: 200;
}

.about-text-box {
    max-width: 40vw;
    display: flex;
    flex-direction: column;
    gap:1.5vh;
    justify-content: center;
    align-items: center;
    text-justify: auto;
    text-align: justify;
}

@media (max-width:1024px) {
    .about-text-box {
        max-width: 70vw;
    }
}

.about-text {
    font-size: 1.6rem;
    margin:0;
}

.know-more-box, .know-more-sub-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.know-more-box {
    padding: 1.5vw 0 1.5vw 0;
    gap:1.5vh;
    background-color: #f4f4f5;
    min-width: 100vw;
}

.know-more-sub-box {
    gap:.5vh;
}

.about-know-more-title {
    font-size: 2rem;
    font-weight: 400;
    margin:0;
    color:rgb(207, 109, 4);
}

.about-know-more-sub-title {
    font-size:1.8rem;
    margin:0;
    font-weight: 200;
}